<template>
  <div class="layout-content-view">
    <div style="padding: 10px;position:relative">
      <a-button style="position: absolute; top:0; right:0;" type="primary" @click="goBack">返回</a-button>
      <a-row type="flex" :gutter="20" style="margin-top:40px">
        <a-col flex="auto">
          <a-card :bordered="false">
            <template>
                <vue-aliplayer-v2
                  :source="source"
                  ref="VueAliplayerV2"
                  :options="options"
                  @ended="handleEnd"
                  @pause="handlePause"
                  @seek="handSeek"
                />
            </template>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import VueAliplayerV2 from 'vue-aliplayer-v2'
import {getVideoUrl,updateCourseState,getStudyTime} from '@/httpsAPI/course/index';

 export default {
      data() {
        return {
          courseNo:'',
          confirmLoading:false,
          visible:false,
          chapterData: [],
          currChapter:{},
          loading: true,
          options: {
            "width": "100%",
            "height": "600px",
            "autoplay": true,
            "isLive": false,
            "rePlay": false,
            "playsinline": true,
            "preload": false,
            "controlBarVisibility": "hover",
            "useH5Prism": true,
            "skinLayout": [
              {
                "name": "bigPlayButton",
                "align": "blabs",
                "x": 30,
                "y": 80
              },
              {
                "name": "controlBar",
                "align": "blabs",
                "x": 0,
                "y": 0,
                "children": [
                  {
                    "name": "playButton",
                    "align": "tl",
                    "x": 15,
                    "y": 12
                  },
                  {
                    "name": "timeDisplay",
                    "align": "tl",
                    "x": 10,
                    "y": 7
                  },
                  {
                    "name": "fullScreenButton",
                    "align": "tr",
                    "x": 10,
                    "y": 12
                  },
                  {
                    "name": "setting",
                    "align": "tr",
                    "x": 15,
                    "y": 12
                  },
                  {
                    "name": "volume",
                    "align": "tr",
                    "x": 5,
                    "y": 10
                  }
                ]
              }
            ]
          },
          source: '',
          testAble: false
        }
      },
      components: {
        VueAliplayerV2
      },

      created() {
        this.courseId = this.$route.query.courseId
        this.videoList()
      },
      mounted(){
      },
      methods: {
        goBack(){
          this.$router.back()
          let studyTime = this.$refs.VueAliplayerV2.getCurrentTime()
          getStudyTime({courseId:this.courseId,studyTime:studyTime}).then(res=>{
            
          })
        },
        handlePause(){
          let studyTime = this.$refs.VueAliplayerV2.getCurrentTime()
          getStudyTime({courseId:this.courseId,studyTime:studyTime}).then(res=>{
          })
        },
        handSeek(){
          this.$refs.VueAliplayerV2.seek(20);
        },
        //视频列表
        videoList(){
          getVideoUrl(this.courseId).then(res=>{
            this.playVideo(res.data)
          })
        },
        // 视频播放
        playVideo(videoUrl){
          if(videoUrl==null || videoUrl=='') {
            this.$message.warning("暂无视频内容可播放！")  
            return
          }
          this.source = videoUrl
          this.$refs.VueAliplayerV2.play()
        },
           /**
         * 播放器事件回调 
         */
        handleEnd(e){
          const that = this
            this.$info({
              title: '提示',
              content: '本章节学习结束',
              okText: '确定',
              onOk() {
                const reqBody = {}
                reqBody.courseNo = that.courseId
                reqBody.states =3
                return updateCourseState(reqBody).then(res=>{
                })
              }
            })
        },
        handleCancel () {
          this.visible = false
        },
        init(courseNo){
          this.visible = true
          this.confirmLoading = true
          this.courseNo = courseNo
        }
      },
    }
</script>
<style lang="less" scoped>
.pass{
  color: #333 !important;
}
.studying{
  background: #eefbc8;
}
</style>